import { Location } from "@angular/common";
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Profile } from "app/model/profile";
import * as FileSaver from "file-saver";
import * as _ from "lodash";
import { FileUploader } from "ng2-file-upload";
import { Popup } from "ng2-opd-popup";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter, switchMap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { CodeCommon } from "../../model/code-common";
import { Comment } from "../../model/comment";
import { InformationBoard } from "../../model/information-board";
import { InformationFiles } from "../../model/information-files";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
import { CommentService } from "../../services/comment.service";
import { InformationBoardService } from "../../services/information-board.service";
import { InformationFilesService } from "../../services/information-files.service";

@Component({
  selector: "app-information-board-detail",
  providers: [InformationBoardService, InformationFilesService, CommentService],
  templateUrl: "./information-board-detail.component.html",
  styles: [
    `
      .my-drop-zone {
        border: dotted 3px lightgray;
      }
      .file-over-class {
        border: dotted 3px red;
      }
      .point {
        cursor: pointer;
      }
      .my-drop-zone:hover {
        border: dashed 2px green;
      }
    `,
  ],
})
export class InformationBoardDetailComponent implements OnInit, OnDestroy {
  @ViewChild("viewContent") viewContent: ElementRef;
  @ViewChild("viewReport") viewReport: ElementRef;

  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  @ViewChild("imageModal") public imageModal: ModalDirective;
  public seletedImage;

  public api_url = environment.api_url;

  public config1 = {
    uiColor: "#FFFFFF",
    scayt_autoStartup: true,
    height: "500",
    fontSize_defaultLabel: "18px",
    extraAllowedContent: "style;*[id,rel](*){*}",
    extraPlugins: "wysiwygarea,divarea,uploadimage",
    uploadUrl: `${environment.api_url}/api/uploader`,
    removePlugins: "liststyle,tabletools,menubutton,contextmenu,wsc,sourcedialog",
    versionCheck: false,
  };

  public config2 = {
    uiColor: "#FFFFFF",
    height: "500",
    fontSize_defaultLabel: "18px",
    extraAllowedContent: "style;*[id,rel](*){*}",
    extraPlugins: "wysiwygarea,divarea,uploadimage",
    disableNativeSpellChecker: false,
    uploadUrl: `${environment.api_url}/api/uploader`,
    removePlugins: "liststyle,tabletools,scayt,wsc,menubutton,contextmenu",
    versionCheck: false,
  };

  public uploader: FileUploader = new FileUploader({ queueLimit: 999 });
  public hasDropZoneOver: boolean = false;

  frequencyList: any[] = [];

  emailEpList: any[] = [];
  emailTo: any[] = [];
  checkedEmailAll: boolean = false;

  inChargeEpList: any[] = [];
  inChargeTo: any[] = [];
  checkedInChargeAll: boolean = false;

  informationCategoryList: any[] = [];
  informationCategory2List: any[] = [];
  informationCategory3List: any[] = [];
  informationCategory4List: any[] = [];

  informationBoard = new InformationBoard();
  selectedBoardId: number;
  content: string;
  isLoad: boolean = false;
  isView: boolean = true;
  isUpdate: boolean = false;

  public informationFiles = new Array<InformationFiles>();
  processedCount = 0;
  totalFiles = 0;

  codeCommon: CodeCommon;
  action: string = "";

  authority: string;
  name: string;
  company: string;

  changedEPList: string;

  comment: string = "";

  constructor(
    private informationBoardService: InformationBoardService,
    private informationFilesService: InformationFilesService,
    private authenticationService: AuthenticationService,
    private codeService: CodeService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private commentService: CommentService
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });
    this.changedEPList = "HELLO WORLD";

    codeService.getCommon(this);

    this.isLoad = false;
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }

    this.codeCommon = codeCommon;

    this.frequencyList = JSON.parse(JSON.stringify(codeCommon.frequencyList));
    this.emailEpList = JSON.parse(JSON.stringify(codeCommon.epList));
    this.inChargeEpList = JSON.parse(JSON.stringify(codeCommon.epList));
    this.informationCategoryList = codeCommon.informationCategoryList;

    this.emailEpList.forEach((x) => {
      if (x.code == "Simon" || x.code == "Liz") {
        x.checked = true;
      }
    });

    this.onSearch();
  }

  ngOnInit() {}

  onSearch() {
    this.route.queryParams.subscribe((params) => {
      this.action = params["action"];

      if (this.action == "home") {
        this.informationBoard.boardCategory = "Daily Report";
        this.onChangeBoardCategory();
      }
    });

    this.route.params
      .pipe(
        switchMap((params: Params) => {
          //if(params['id'] === undefined) return new Error('Wrong params');
          this.selectedBoardId = params["id"];
          if (this.selectedBoardId == undefined) {
            this.isView = false;
            this.isUpdate = false;
            return null;
          } else {
            return this.informationBoardService.get(+params["id"]);
          }
        })
      )
      .subscribe(
        (data) => {
          this.informationBoard = data;

          if (this.selectedBoardId) {
            this.commentService
              .getList(this.selectedBoardId)
              .subscribe((data) => {
                this.informationBoard.commentList = data;
              });
          }

          //console.log(this.informationFiles.boardBody);
          //console.log("ngOnInit : " + this.informationFiles.id);
          //console.log("boardBody.length 1 : " + this.informationFiles.boardBody.length);
          this.setCombox();

          this.isView = this.informationBoard.id === undefined ? false : true;

          this.getFiles();

          if (this.isView) {
            setTimeout(() => {
              if (this.viewContent != undefined) {
                this.viewContent.nativeElement.style.fontSize = "5";
                this.viewContent.nativeElement.innerHTML =
                  this.informationBoard.boardBody;
              }
            }, 1000);
          } else {
            if (this.isLoad) {
              this.content = this.informationBoard.boardBody;

              setTimeout(() => {
                if (this.viewReport != undefined) {
                  this.viewReport.nativeElement.style.fontSize = "5";
                  this.viewReport.nativeElement.innerHTML =
                    this.informationBoard.viewReport;
                }
              }, 1000);
            }
          }

          if (
            this.informationBoard.emailAlarm != undefined &&
            this.informationBoard.emailAlarm != ""
          ) {
            this.emailTo = this.informationBoard.emailAlarm
              .replace(" ", "")
              .split(",");
            this.emailEpList.forEach((ep) => {
              ep.checked = this.isCheckedEmail(ep.code);
            });
          } else {
            this.emailEpList.forEach((ep) => {
              ep.checked = false;
            });
          }

          if (
            this.informationBoard.inCharge != undefined &&
            this.informationBoard.inCharge != ""
          ) {
            this.inChargeTo = this.informationBoard.inCharge
              .replace(" ", "")
              .split(",");
            this.inChargeEpList.forEach((ep) => {
              ep.checked = this.isCheckedInCharge(ep.code);
            });
          } else {
            this.inChargeEpList.forEach((ep) => {
              ep.checked = false;
            });
          }
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }

  setCombox() {
    if (
      this.informationBoard.boardCategory != undefined &&
      this.informationCategoryList.length > 0
    ) {
      if (this.informationBoard.boardCategory == "Daily Report") {
        this.informationCategory2List = this.codeCommon.epList;
        this.informationBoard.boardCategoryMiddle = this.name;
      } else {
        let cat2 = this.informationCategoryList.find(
          (x) => x.code == this.informationBoard.boardCategory
        );

        if (cat2 != undefined) {
          this.informationCategory2List = cat2.children;
        }
      }

      if (this.informationCategory2List !== undefined) {
        if (this.informationBoard.boardCategory == "School") {
          this.informationCategory3List = this.codeCommon.schoolNameList.filter(
            (code) => {
              //(code.value4 ? code.value4.split(",")[0] : "No") == this.informationBoard.boardCategoryMiddle)
              if (code.value4) {
                var codes = code.value4.split(", ");
                for (var i = 0; i < codes.length; i++) {
                  if (codes[i] == this.informationBoard.boardCategoryMiddle)
                    return true;
                }
              }
            }
          );
        } else {
          let cat3 = this.informationCategory2List.find(
            (x) => x.code == this.informationBoard.boardCategoryMiddle
          );

          if (cat3 != undefined) {
            this.informationCategory3List = cat3.children;
          }
        }
      }

      if (this.informationCategory3List !== undefined) {
        if (this.informationBoard.boardCategory == "School") {
          let cat3 = this.informationCategory2List.find(
            (x) => x.code == this.informationBoard.boardCategoryMiddle
          );

          if (cat3 != undefined) {
            this.informationCategory4List = cat3.children;
          }
        } else {
          let cat4 = this.informationCategory3List.find(
            (x) => x.code == this.informationBoard.boardCategoryThird
          );

          if (cat4 != undefined) {
            this.informationCategory4List = cat4.children;
          }
        }
      }
    }
  }

  ngOnDestroy() {
    console.log("onDestroy");
  }

  getFiles() {
    if (this.informationBoard.id !== undefined) {
      this.informationFilesService.getList(this.informationBoard.id).subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          //console.log("data.length : " + data.length);

          this.informationFiles = new Array<InformationFiles>();
          data.forEach((file) => this.informationFiles.push(file));
        },
        (error) => {
          console.log("error : ", error);
        }
      );
    }
  }

  onChangeBoardCategory() {
    this.informationBoard.boardCategoryMiddle = "";

    if (this.informationBoard.boardCategory == "Daily Report") {
      this.informationCategory2List = this.codeCommon.epList.filter(
        (x) => x.code == this.name
      );
      this.informationBoard.boardCategoryMiddle = this.name;

      this.informationBoard.boardHead =
        this.getToday() + " " + this.name + " Report";

      this.onSearchDailyReport();
    } else {
      this.informationCategory2List = this.informationCategoryList.find(
        (x) => x.code === this.informationBoard.boardCategory
      ).children;
      //this.informationBoard.boardHead = "";

      //this.content = "";
    }

    this.informationBoard.boardCategoryThird = "";
    this.informationBoard.boardCategoryFourth = "";
  }

  onChangeBoardCategoryMiddle() {
    if (this.informationBoard.boardCategory == "School") {
      this.informationCategory3List = this.codeCommon.schoolNameList.filter(
        (code) => {
          //(code.value4 ? code.value4.split(",")[0] : "No") == this.informationBoard.boardCategoryMiddle)
          if (code.value4) {
            var codes = code.value4.split(", ");
            for (var i = 0; i < codes.length; i++) {
              if (codes[i] == this.informationBoard.boardCategoryMiddle)
                return true;
            }
          }
        }
      );
    } else {
      this.informationCategory3List = this.informationCategory2List.find(
        (x) => x.code === this.informationBoard.boardCategoryMiddle
      ).children;
    }

    this.informationBoard.boardCategoryThird = "";
    this.informationBoard.boardCategoryFourth = "";
  }

  onChangeBoardCategoryThird() {
    if (this.informationBoard.boardCategory == "School") {
      this.informationCategory4List = this.informationCategory2List.find(
        (x) => x.code === this.informationBoard.boardCategoryMiddle
      ).children;
    } else {
      this.informationCategory4List = this.informationCategory3List.find(
        (x) => x.code === this.informationBoard.boardCategoryThird
      ).children;
    }

    this.informationBoard.boardCategoryFourth = "";
  }

  onChange(ckeditor) {
    //console.log("onChange content1 : " + this.content);
  }

  onReady(ckeditor) {
    this.isLoad = true;

    if (this.informationBoard.boardBody !== undefined) {
      this.content = this.informationBoard.boardBody;

      setTimeout(() => {
        if (this.viewReport != undefined) {
          this.viewReport.nativeElement.style.fontSize = "5";
          this.viewReport.nativeElement.innerHTML =
            this.informationBoard.viewReport;
        }
      }, 1000);
    }
  }

  onFocus(ckeditor) {
    //console.log("onFocus : ");
  }

  onBlur(ckeditor) {
    //console.log("onBlur : ");
  }

  onNewMode() {
    this.informationBoard = new InformationBoard();
    this.content = "";
    this.isView = false;
    this.isUpdate = false;
  }

  onUpdateMode() {
    this.isView = false;
    this.isUpdate = true;
    this.content = this.informationBoard.boardBody;

    setTimeout(() => {
      if (this.viewReport != undefined) {
        this.viewReport.nativeElement.style.fontSize = "5";
        this.viewReport.nativeElement.innerHTML =
          this.informationBoard.viewReport;
      }
    }, 1000);
  }

  readQueue(action) {
    for (var idx = 0; idx < this.uploader.queue.length; idx++) {
      this.readFile(action, this.uploader.queue[idx]._file);
    }
  }

  readFile(action, f) {
    var fileReader = new FileReader();

    var that = this;
    fileReader.onload = function () {
      //console.log("content   : " + window.btoa(fileReader.result));
      that.addFile(
        action,
        window.btoa(fileReader.result as string),
        f.name,
        f.size
      );
    };

    fileReader.readAsBinaryString(f);
  }

  addFile(action, result, filename, filesize) {
    let informationFile = new InformationFiles();

    informationFile.filesize = filesize;
    informationFile.filename = filename;
    informationFile.file = result;
    informationFile.mode = "I";

    this.informationFiles.push(informationFile);
    this.processedCount++;

    //console.log('this.processedCount : ' + this.processedCount + ', this.totalFiles : ' + this.totalFiles);

    if (this.processedCount == this.totalFiles) {
      if (action == "create") this.onCreate();
      else if (action == "update") this.onUpdate();
    }
    //this.informationFiles.forEach(function(elem) {
    //   console.log("File Name : " + elem.filename);
    //});
  }

  onSearchDailyReport() {
    this.informationBoardService
      .searchDailyReport(this.informationBoard)
      .subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));

          if (data != undefined && data != null) {
            if (data.id != undefined && data.id != null) {
              this.informationBoard = data;
              this.content = this.informationBoard.boardBody;
              this.isUpdate = true;

              setTimeout(() => {
                if (this.viewReport != undefined) {
                  this.viewReport.nativeElement.style.fontSize = "5";
                  this.viewReport.nativeElement.innerHTML =
                    this.informationBoard.viewReport;
                }
              }, 1000);
            } else {
              this.content = data.boardBody;

              setTimeout(() => {
                if (this.viewReport != undefined) {
                  this.viewReport.nativeElement.style.fontSize = "5";
                  this.viewReport.nativeElement.innerHTML = data.viewReport;
                  this.informationBoard.viewReport = data.viewReport;
                }
              }, 1000);
            }
          }
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }

  onCreateBoardAndSend() {
    this.informationBoard.sendToManager = "Y";
    this.onCreateBoard();
  }

  onCreateBoard() {
    this.totalFiles = this.uploader.queue.length;

    if (this.totalFiles > 0) {
      this.readQueue("create");
    } else {
      this.onCreate();
    }
  }

  onCreate() {
    this.informationBoard.boardBody = this.content;

    var that = this;
    this.informationFiles.forEach(function (elem) {
      that.informationBoard.informationFilesParams.push(elem);
    });

    this.informationBoard.emailAlarm = this.selectedEmailEp.toString();
    this.informationBoard.inCharge = this.selectedInChargeEp.toString() + ",";

    this.informationBoardService.create(this.informationBoard).subscribe(
      (data) => {
        console.log("create successful");

        //this.uploader.uploadAll();

        this.popupMessage = "Succeed in creating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onUpdateBoardAndSend() {
    this.informationBoard.sendToManager = "Y";
    this.onUpdateBoard();
  }

  onUpdateBoard() {
    this.totalFiles = this.uploader.queue.length;

    if (this.totalFiles > 0) {
      this.readQueue("update");
    } else {
      this.onUpdate();
    }
  }

  onUpdate() {
    this.informationBoard.boardBody = this.content;
    this.informationBoard.informationFilesParams =
      new Array<InformationFiles>();

    var that = this;
    this.informationFiles.forEach(function (elem) {
      that.informationBoard.informationFilesParams.push(elem);
    });

    this.informationBoard.emailAlarm = this.selectedEmailEp.toString();
    this.informationBoard.inCharge = this.selectedInChargeEp.toString() + ",";

    this.informationBoardService.update(this.informationBoard).subscribe(
      (data) => {
        console.log("update successful");
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in updating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to update";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onDeleteBoard() {
    this.informationBoardService.delete(this.informationBoard).subscribe(
      (data) => {
        console.log("delete successful");

        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to delete";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  clickOKButton() {
    this.popup1.hide();
    if (
      this.popupMessage === "Succeed in creating" ||
      this.popupMessage === "Succeed in updating" ||
      this.popupMessage === "Succeed in deleting"
    ) {
      this.router.navigate(["/informationBoard"]);
    }
  }

  get previewImage() {
    return "";
    //return this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64,'+ this.seletedImage);
  }

  onFileWithoutDeleting(
    informationFiles: InformationFiles[]
  ): InformationFiles[] {
    let result: InformationFiles[] = [];

    for (
      var i = 0;
      informationFiles != undefined && i < informationFiles.length;
      i++
    ) {
      //console.log("informationFiles["+i+"].mode : " + informationFiles[i].mode);
      if (
        informationFiles[i].mode === undefined ||
        informationFiles[i].mode == ""
      ) {
        result.push(informationFiles[i]);
      }
    }
    return result;
  }

  onDeleteFile(fileName) {
    this.informationFiles.find(function (item) {
      return item.filename === fileName;
    }).mode = "D";
  }

  onSaveFile(fileContent, fileName) {
    //this.seletedImage = val;
    //console.log("onDisplayImage");
    //this.imageModal.show();

    var byteContents = window.atob(fileContent);
    var byteNumbers = new Array(byteContents.length);

    for (var i = 0; i < byteContents.length; i++) {
      byteNumbers[i] = byteContents.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray]);
    FileSaver.saveAs(blob, fileName);
  }

  //example
  //downloadFile(data){
  //  var blob = new Blob([data], { type: 'text/csv' });
  //  var url= window.URL.createObjectURL(blob);
  //  window.open(url);
  //}

  onCloseImage() {
    this.imageModal.hide();
  }

  fileOverBase(e: any) {
    this.hasDropZoneOver = e;
  }

  get selectedEmailEp() {
    return this.emailEpList
      .filter((opt) => opt.checked)
      .map((opt) => opt.value);
  }

  onEmailAll() {
    this.emailEpList.forEach((x) => {
      if (x.code == "Simon" || x.code == "Liz") {
        x.checked = true;
      } else {
        x.checked = this.checkedEmailAll;
      }
    });
  }

  isCheckedEmail(code: string) {
    return this.emailTo.some((x) => x == code);
  }

  get selectedInChargeEp() {
    return this.inChargeEpList
      .filter((opt) => opt.checked)
      .map((opt) => opt.value);
  }

  onInChargeAll() {
    this.inChargeEpList.forEach((x) => {
      x.checked = this.checkedInChargeAll;
    });
  }

  isCheckedInCharge(code: string) {
    return this.inChargeTo.some((x) => x == code);
  }

  getToday() {
    let today = new Date();
    let yyyy = today.getFullYear();
    let dd = today.getDate();
    return (
      dd + " " + today.toLocaleString("en-us", { month: "short" }) + " " + yyyy
    );
  }

  createComment() {
    let comment = new Comment();
    comment.comment = this.comment;
    comment.createUser = this.name;
    comment.informationBoardId = this.selectedBoardId;

    this.commentService.create(comment).subscribe((data) => {
      this.comment = "";
      if (this.informationBoard.commentList) {
        this.informationBoard.commentList = [
          data,
          ...this.informationBoard.commentList,
        ];
      } else {
        this.informationBoard.commentList = [data];
      }
    });
  }

  likeComment(comment: Comment) {
    if (comment.likes && _.includes(comment.likes.split(","), this.name)) {
      comment.likes = _.filter(
        comment.likes.split(","),
        (name) => name != this.name
      ).join(",");
    } else {
      comment.likes =
        comment.likes == null || comment.likes == ""
          ? this.name
          : comment.likes + `,${this.name}`;
    }

    this.commentService.update(comment).subscribe();
  }

  deleteComment(comment: Comment) {
    this.commentService.delete(comment).subscribe((data) => {
      this.informationBoard.commentList = _.filter(
        this.informationBoard.commentList,
        (c) => c.id != data.id
      );
    });
  }
}
